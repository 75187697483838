<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 top-14 flex items-end px-4 py-6 sm:items-start sm:p-6">
        <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <transition
                v-for="(notification, index) in notifications"
                :key="index"
                enter-active-class="transform ease-out duration-300 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <div class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div class="p-4">
                        <div class="flex items-start">
                            <div class="flex-shrink-0">
                                <component
                                    :is="matrix[notification.type].icon"
                                    class="h-6 w-6"
                                    :class="matrix[notification.type].text"
                                    aria-hidden="true"></component>
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="text-sm font-medium text-gray-900" v-text="notification.title"></p>
                                <p class="mt-1 text-sm text-gray-500" v-text="notification.message"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script setup>
    import { CheckCircleIcon, XCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from "@heroicons/vue/24/outline";

    import { reactive, onMounted } from "vue";
    const { $bus } = useNuxtApp();

    const notifications = reactive([]);
    const matrix = {
        success: {
            text: "text-green-400",
            icon: CheckCircleIcon,
        },
        danger: {
            text: "text-red-400",
            icon: XCircleIcon,
        },
        warning: {
            text: "text-orange-400",
            icon: ExclamationTriangleIcon,
        },
        info: {
            text: "text-blue-400",
            icon: InformationCircleIcon,
        },
    };
    onMounted(() => {
        $bus.on("notificate", (payload) => {
            notifications.push(payload);

            setTimeout(() => {
                notifications.shift();
            }, 5000);
        });
    });
</script>
